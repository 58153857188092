import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { FormatMoney } from "../../common/helpers";

import API from "../../common/API";

import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { parsedUser } from "../../common/GetCurrentUser";
import { toast } from "react-toastify";
import { date } from "yup";

const QuickAddEditCustomerSale = (props) => {
  const user = parsedUser();
  const [filter, setFilter] = useState("");
  const [records, setRecords] = useState([]);
  const bindRemote = async () => {
    if (!user) {

      return null;
    }
    let request = await API.getAction(
      `customers/byDate?organization=${user.OrganizationId}&date=${props.date}&isBulk=false`
    );

    if (request.status === 200) {
      setRecords(request.data.response);
    }
  };

  const handleChange = (index, element) => {
    const newCollection = records.map((item, indexElement) => {
      if (indexElement === index) {
        return element;
      } else {
        return item;
      }
    });

    setRecords(newCollection);
  };

  const handleSubmit = async (obj, event) => {
    try {
      console.log("values to submit", obj);

      var query = {
        id: obj.id,
        customerId: obj.customerId,
        date: obj.date,
        amount: obj.amount,
        granelAmount: obj.granelAmount,
        saleUsd: obj.saleUsd,
        saleHtg: obj.saleHtg,
        isCredit: obj.isCredit

      }

      let request = await API.postAction(`customers/VolumeAddEdit`, query);

      console.log("response from adding", request);
      if (request.status === 200) {
        toast.success("Se agrego correctamente");
        await bindRemote();
      }
    } catch (e) {
      console.error(e);
      if (e.response.data.status === "error") {
        console.log(e.response.data.response);
        //   alert(e.response.data.response);
        toast.error(e.response.data.response);
      }
      // toast.error(e);
    }
  };
  let totalSummary = 0, totalSummaryGranel = 0;

  useEffect(() => {
    bindRemote();
  }, [props.date]);



  return (

    <Card className="shadow-sm mb-4 bg-white">
      <Card.Header>
        <div className="row">
          <div className="col">
            <Card.Title>
              Venta por clientes
              <small> - Detalle</small>{" "}
            </Card.Title>
          </div>
          <div className="col text-right">
            <input
              type="text"
              className="form-control"
              placeholder="search"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            ></input>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-0" style={{ maxHeight: "65vh", overflow: "auto" }}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Tipo Cliente</th>
              <th>Cliente</th>
              <th>Detalle (GL)</th>
              <th>Granel (GL)</th>
              <th>Venta USD</th>
              <th>Venta HTG</th>
              {/* <th>A Crédito</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {records &&
              Array.isArray(records) &&
              records
                .filter(x => x.customerName.toLowerCase().includes(filter.toLowerCase()))
                .map((item, index) => {
                  console.table(item);
                  let obj = item;
                  totalSummaryGranel += obj.granelAmount * 1;
                  totalSummary += obj.amount * 1;
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{obj.customerTypeName ?? "-"}</td>
                      <td>{obj.customerName}</td>
                      <td>
                        <input
                          type="number"
                          name="amount"
                          className="form-control"
                          value={obj.amount ?? "0"}
                          onChange={(e) => {
                            obj.amount = e.target.value * 1;
                            handleChange(index, obj);
                          }}

                          min={0}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="number"
                          name="granelAmount"
                          className="form-control"
                          value={obj.granelAmount ?? "0"}
                          onChange={(e) => {
                            obj.granelAmount = e.target.value * 1;
                            handleChange(index, obj);
                          }}

                          min={0}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="number"
                          name="saleUsd"
                          className="form-control"
                          value={obj.saleUsd ?? "0"}
                          onChange={(e) => {
                            obj.saleUsd = e.target.value * 1;
                            handleChange(index, obj);
                          }}
                          min={0}
                        ></input>
                      </td>
                      <td>
                        <input
                          type="number"
                          name="saleHtg"
                          className="form-control"
                          value={obj.saleHtg ?? "0"}
                          onChange={(e) => {
                            obj.saleHtg = e.target.value * 1;
                            handleChange(index, obj);
                          }}

                          min={0}
                        ></input>
                      </td>

                      {/* <td>
                        <select
                          className="form-control"
                          name="IsCredit"
                          value={obj.isCredit ?? ""}
                          onChange={(e) => {
                            obj.IsCredit = e.target.value;
                            handleChange(index, obj);
                          }}
                        >
                          <option value="">-</option>
                          <option value="true">Crédito</option>
                          <option value="false">Débito</option>
                        </select>
                      </td> */}
                      <td className="text-right">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            handleSubmit(obj);
                          }}
                        >
                          <i className="fas fa-save"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </Card.Body>
      <Card.Footer>
        <span className="badge bg-primary text-white me-2 py-2 px-4">TOTAL VENTA: <b>{totalSummary + totalSummaryGranel} gl</b></span>
        <span className="badge bg-secondary text-dark me-2 py-2 px-4">
          DETALLE: <b>{totalSummary} gl</b></span>
        <span className="badge bg-secondary text-dark me-2 py-2 px-4">GRANEL: <b>{totalSummaryGranel} gl</b></span>
      </Card.Footer>
    </Card>

  );
};

export default QuickAddEditCustomerSale;
