import ReportSectionDTO from "./ReportSectionDTO";

const ReportSetions: ReportSectionDTO[] = [
  {
    name: "Reports",
    parentId: "admin",
    requiredParent: true,
    sections: [
      {
        reference: "customers",
        label: "Customers",
      },
    ],
  },
];

export default ReportSetions;
