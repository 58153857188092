
export const Routes = {
    // pages
    Home: { path: "/" },
    DashboardPage: { path: "/dashboard", clean: "/dashboard" },
    DashboardSummary: { path: "/summary", clean: "/summary" },
    // Inventory: { path: "/inventory/:id?", clean: "/inventory/" },
    Settings: { path: "/settings" },

    Devices: { path: "/devices" },
    DevicesDetails: { path: "/devices/details/:id", clean: "/devices/details/" },

    Geofences: { path: "/geofences" },

    Users: { path: "/users" },
    ProjectDetails: { path: "/Projects/detail/:id", clean: "/Projects/Detail/" },
    SalesRecord: { path: "/record/:id?", clean: "/record/" },
    Sales: { path: "/Sales/:id?", clean: "/Sales/" },

    Reports: { path: "/reports/:id?", clean: "/reports/" },

    Metters: { path: "/metters" },
    Profile: { path: "/profile" },
    Providers: { path: "/providers" },
    SignIn: { path: "/signin" },
    Register: { path: "/Register" },
    ForgotPassword: { path: "/ForgotPassword" },
    ResetPassword: { path: "/ResetPassword" },
    Admin: { path: "/Admin" },
    NotFound: { path: "/examples/NotFound" },
    ServerError: { path: "/examples/ServerError" },

    Inventory: { path: "/inventory/:id", clean: "/inventory/" },
    ProductDetails: { path: "/Products/details/:id", clean: "/Products/details/" },
    Services: { path: "/Services", clean: "/Services" },
    Customers: { path: "/Customers", clean: "/Customers" },
    CustomerDetails: { path: "/Customers/Details/:id", clean: "/Customers/Details/" },

};