import React, { useEffect, useState } from "react";
import { parsedUser } from "../../common/GetCurrentUser";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import API from "../../common/API";
import moment from "moment";
import { FormatMoney } from "../../common/helpers";
import { Col, Row } from "react-bootstrap";

const TotalSaleAndDeliveredRecord = (props) => {
  const [viewMode, setViewMode] = useState("graph");
  const [from, setFrom] = useState(
    moment().add("days", -30).format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [state, setState] = useState(null);
  const [records, setRecords] = useState([]);
  const user = parsedUser();

  const toggleViewMode = (mode) => {
    setViewMode(mode);
  };
  const bindDataRemote = async () => {
    let query = `organizationId=${user.OrganizationId}&from=${from}&to=${to}`;
    const request = await API.getAction(`tank/TotalSaleAndDelivered?${query}`);

    console.log(request);

    setRecords(request.data.response);
  };

  useEffect(() => {
    if (records) {
      const labels = records.map((item) =>
        moment(item.date).format("MMM DD, YYYY")
      );
      setState({
        //  [],
        labels,
        datasets: [
          {
            label: "Vendido",
            data: records.map((item) => item.totalSale),
            backgroundColor: "red",
          },
          {
            label: "Comprado",
            data: records.map((item) => item.totalPurchased),
            backgroundColor: "blue",
          },
          {
            label: "BBs",
            data: records.map((item) => item.totalGenerated),
            backgroundColor: "green",
          },
        ],
      });
    }
  }, [records]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Total Purchased",
      },
    },
  };
  useEffect(() => {
    bindDataRemote();
  }, []);

  useEffect(() => {
    bindDataRemote();
  }, [from, to]);

  if (!state || !records) {
    return <></>;
  }

  let totalSale = 0;
  let totalPurchased = 0;
  let totalGenerated = 0;
  let totalDelivered = 0;
  return (
    <>
      <div className="card shadow-sm mb-2">
        <div className="card-header">
          <Row>
            <Col>
              <span className="display-6 d-inline text-uppercase">
                Total Venta (GL)
              </span>
            </Col>
            <Col className="text-end col-auto">
              <div className="input-group">
                <input
                  type="date"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                  className="form-control"
                />
                <input
                  type="date"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                  className="form-control"
                />
              </div>
            </Col>
            <Col className="text-end ml-auto col-auto">
              <div className="btn-group">
                <button
                  className={
                    "btn btn-sm " +
                    (viewMode === "graph"
                      ? "btn-primary"
                      : "btn-outline-primary")
                  }
                  onClick={() => toggleViewMode("graph")}
                >
                  <i className="fas fa-chart-bar"></i>
                </button>
                <button
                  className={
                    "btn btn-sm " +
                    (viewMode === "table"
                      ? "btn-primary"
                      : "btn-outline-primary")
                  }
                  onClick={() => toggleViewMode("table")}
                >
                  <i className="fa fa-table"></i>
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="card-body">
          {viewMode === "graph" && <Line options={options} data={state} />}

          {viewMode === "table" && (
            <div className="table-responsive">
              <table className="table table-striped border">
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Vendido</th>
                    <th>Entregado</th>
                    <th>Comprado</th>
                    <th>BBs</th>
                    <th>% BBs Generados</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((item, index) => {
                    totalSale += item.totalSale;
                    totalPurchased += item.totalPurchased;
                    totalGenerated += item.totalGenerated;
                    totalDelivered += item.totalDelivered;
                    return (
                      <tr key={index}>
                        <td>{moment(item.date).format("MMM DD, YYYY")}</td>
                        <td>{FormatMoney(item.totalSale)}</td>
                        <td>{FormatMoney(item.totalDelivered)}</td>
                        <td>{FormatMoney(item.totalPurchased)}</td>
                        <td>{FormatMoney(item.totalGenerated)}</td>
                        <td>
                          {(
                            (item.totalGenerated / item.totalSale) *
                            100
                          ).toFixed(2)}
                          %
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th></th>
                    <th>
                      <h4>{FormatMoney(totalSale)}</h4>
                    </th>
                    <th>
                      <h4>{FormatMoney(totalDelivered)}</h4>
                    </th>
                    <th>
                      <h4>{FormatMoney(totalPurchased)}</h4>
                    </th>

                    <th>
                      <h4>{FormatMoney(totalGenerated)}</h4>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TotalSaleAndDeliveredRecord;
